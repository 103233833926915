import * as React from 'react'
import useStateRef from 'react-usestateref'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title, useGetList, useStore, useDataProvider, useRedirect } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import OrderTypeSelection from './partials/OrderTypeSelection'
import ProductSearch from './partials/ProductSearch'
import OrderLines from './partials/OrderLines'
import RefundScale from '../Patients/partials/RefundScale'
import NewTemplateModal from './partials/NewTemplateModal'
import dpsProvider from '../../config/dpsProvider'
import fastOrderProvider from '../../config/fastOrderProvider'
import ezwmProvider from '../../config/ezwmProvider'

const refundRangeOptions = [
    { value: 1, label: '1 miesiąc' },
    { value: 2, label: '2 miesiące' },
    { value: 3, label: '3 miesiące' },
]

const getTitle = (orderProps) => {
    let result = ''
    switch (orderProps.orderType) {
        case 1:
            result += 'Zamówienie z dofinansowaniem NFZ, '
            break
        case 2:
            result += 'Zamówienie bez dofinansowania NFZ, '
            break
    }

    switch (orderProps.invoice) {
        case 'patient':
            result += 'faktura na podopiecznego'
            break
        case 'dps':
            result += 'faktura na DPS'
            break
    }

    return result
}

const checkoutStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const currencyFormatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
})

export default () => {
    const dataProvider = useDataProvider()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const redirect = useRedirect()

    const [patients, setPatients] = React.useState([])
    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', false)
    const [refundRange, setRefundRange] = React.useState(1)
    const [expandAll, setExpandAll] = React.useState(false)
    const [shouldSelectAll, setShouldSelectAll] = React.useState(true)
    const [expanded, setExpanded, expandedRef] = useStateRef({})
    const [modalOpen, setModalOpen] = React.useState(false)
    const [sending, setSending, sendingRef] = useStateRef(false)
    const [refundStatuses, setRefundStatuses] = React.useState(null)

    const [dpsDetails, setDpsDetails] = useStore('order_dps_details', [])
    const [summaryAll, setSummaryAll] = useStore('order_dps_summary', {})
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const getRefundPrice = (row, patient) => {
        return parseFloat(row?.dpsPrice ?? row?.[`refund_price_${patient?.refundDocument?.refundReduction}`]) || 0
    }

    const updateSummaries = (orderDetails, setOrderDetails, dpsPreferences, patients, summaryAll, setSummaryAll) => {
        let _total = 0
        let _patient = 0
        let _dps = 0
        let _nfz = 0

        orderDetails?.map((p) => {
            let patient = patients.find((_p) => _p._id == p.patient_id)


            p.summaryTotal = p.products?.reduce((accumulator, currentValue) => accumulator + currentValue.price * currentValue.quantity, 0)
            p.summaryPatient =
                dpsPreferences.orderType == 1
                    ? p.products?.reduce(
                          (accumulator, currentValue) => accumulator + getRefundPrice(currentValue, patient) * currentValue.quantity,
                          0
                      )
                    : 0

            p.summaryDps = p.summaryTotal - p.summaryPatient
            p.summaryNfz = p.summaryTotal - p.summaryDps

            if (p.selected) {
                _total += p.summaryTotal
                _patient += p.summaryPatient
                _dps += p.summaryDps
                _nfz += p.summaryNfz
            }
        })
        
        if (orderDetails?.length > 0) {
            summaryAll.summaryTotal = _total
            summaryAll.summaryPatient = _patient
            summaryAll.summaryDps = _dps
            summaryAll.summaryNfz = _nfz

            setOrderDetails(orderDetails)
            setSummaryAll(summaryAll)
        }
    }

    React.useEffect(() => {
        forceUpdate()
    }, shouldSelectAll)

    React.useEffect(() => {
        updateSummaries(dpsDetails, setDpsDetails, dpsPreferences, patients, summaryAll, setSummaryAll)
        forceUpdate()
    }, [refundStatuses?.length, JSON.stringify(dpsDetails), JSON.stringify(dpsPreferences), patients.length])


    React.useEffect(() => {
        const deptFilter = dpsPreferences?.department
            ? Object.entries(dpsPreferences?.department)
                  .filter((key, val) => key[1] == true)
                  .map((x) => x[0].split('/')[2])
            : null
        if (!deptFilter?.length) {
            setPatients([])
            return
        }

        dataProvider
            .getList('patients', {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'id', order: 'DESC' },
                filter: { department: deptFilter },
            })
            .then((result) => {
                setPatients(result.data)
                const defaultDpsDetails = []

                result?.data?.map((patient) => {
                    return fastOrderProvider.getPatientProducts(patient._id).then((products) => {
                        const existsPatient = dpsDetails?.findIndex((obj) => obj.patient_id == patient._id)
                        const haveProducts = dpsDetails?.[existsPatient]?.products?.length > 0
                        if (existsPatient == -1 && !haveProducts) {
                            defaultDpsDetails.push({ patient_id: patient._id, products: products })
                        }
                        setTimeout(()=>setDpsDetails([...dpsDetails, ...defaultDpsDetails]),0)
                    })
                })

                ezwmProvider
                    .getRefundDocsStatusesByPatients(result?.data?.map((patient) => patient._id))
                    .then((statuses) => setRefundStatuses(statuses))
                return result.data
            })
    }, [dpsPreferences])

    const selectAll = () => {
        setDpsDetails(dpsDetails.map((d) => ({ ...d, selected: d?.products?.length > 0 && shouldSelectAll })))
        setShouldSelectAll(!shouldSelectAll)
        setTimeout(() => forceUpdate(), 0)
    }

    const PatientSummary = ({ patient, active = false, dpsDetails, _refundStatuses, dpsPreferences }) => {
        const patientStatuses = _refundStatuses?.[patient._id]

        const orderDetails = dpsDetails?.find((obj) => obj.patient_id == patient._id)
        const subTotalSummary = orderDetails?.products?.reduce(
            (_accumulator, _currentValue) => _accumulator + _currentValue.price * _currentValue.quantity,
            0
        )

        const subPatientSummary =
            dpsPreferences.orderType == 1
                ? orderDetails?.products?.reduce(
                      (accumulator, currentValue) => accumulator + getRefundPrice(currentValue, patient) * currentValue.quantity,
                      0
                  )
                : 0

        return !active ? (
            <Box sx={{ display: 'flex', flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, minWidth: 400, marginRight: 5 }}>
                    <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                        patient._id
                    })`}</Typography>
                    {dpsPreferences.orderType == 1 && <Typography>{patient?.refundDocument?.spanLimit * refundRange} szt</Typography>}
                </Box>

                <Box sx={{ display: 'flex', flex: 2, justifyContent: 'space-around' }}>
                    {orderDetails?.products && (
                        <>
                            <Divider orientation="vertical" />
                            <Box>
                                {orderDetails?.products?.map((p) => (
                                    <Typography>{p.name}</Typography>
                                ))}
                            </Box>
                            <Box>
                                <Typography>wartość</Typography>
                                <Typography fontWeight="bold">{currencyFormatter.format(subTotalSummary??0)}</Typography>
                            </Box>
                            {dpsPreferences?.orderType == 1 && (
                                <Box>
                                    <Typography>dopłata pacjenta</Typography>
                                    <Typography fontWeight="bold">{currencyFormatter.format(subPatientSummary??0)}</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                    patient._id
                })`}</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Typography marginRight={5}>Oddział:</Typography>
                    <Typography fontWeight="bold">{patient._department.name}</Typography>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography marginRight={5}>Miesięczna ilość:</Typography>
                        <Typography fontWeight="bold">{patient?.refundDocument.spanLimit}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography marginRight={5}>Dostępna ilość:</Typography>
                        <Typography fontWeight="bold">{patient?.refundDocument.availableLimit}</Typography>
                    </Box>
                </Box>
                {patientStatuses && (
                    <Box>
                        <RefundScale refundMonths={Object.entries(patientStatuses).map(([key, value]) => ({ [key]: value }))} />
                    </Box>
                )}
            </Box>
        )
    }

    const handleSelect = (patientId) => {
        const dpsDetailsIndex = getPatientIndex(patientId)
        if (dpsDetails[dpsDetailsIndex] && dpsDetails[dpsDetailsIndex]?.products?.length) {
            dpsDetails[dpsDetailsIndex].selected = !dpsDetails[dpsDetailsIndex].selected
            setDpsDetails(dpsDetails)
            setTimeout(() => forceUpdate(), 0)
        }
    }

    const handleCash = (patientId) => {
        const dpsDetailsIndex = getPatientIndex(patientId)
        if (dpsDetails[dpsDetailsIndex]) {
            dpsDetails[dpsDetailsIndex].isCash = !dpsDetails[dpsDetailsIndex].isCash
            setDpsDetails(dpsDetails)
            setTimeout(() => forceUpdate(), 0)
        }
    }

    const handleChange = (patientId) => (event) => {
        expandedRef.current[patientId] = !expandedRef.current[patientId]
        setExpanded(expandedRef.current)
        setTimeout(() => forceUpdate(), 0)
    }

    const getPatientIndex = (patientId) => dpsDetails?.findIndex((obj) => obj.patient_id == patientId)

    const canSubmitOrder = (onlySelected = false) => {
        return (
            dpsDetails?.filter((patient) => (onlySelected ? patient?.selected && patient?.products?.length > 0 : patient?.products?.length > 0))
                ?.length > 0
        )
    }

    const submitOrder = () => {
        setSending(true)
        if (!canSubmitOrder(true)) {
            enqueueSnackbar('Brak zaznaczonych pacjentów lub brak produktów', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            return
        }
        const orderLines = dpsDetails
            .filter((oL) => oL?.selected)
            .map((_oL) => ({
                patientId: _oL.patient_id,
                isCash: _oL.isCash ?? false,
                products: _oL?.products?.map(({ description, categories, attributes, features, ...other }) => other),
                summary: {
                    summaryTotal: _oL.summaryTotal,
                    summaryDps: _oL.summaryDps,
                    summaryNfz: _oL.summaryNfz,
                    summaryPatient: _oL.summaryPatient,
                },
            }))
        const formData = new FormData()
        formData.append('orderPreferences', JSON.stringify({...dpsPreferences,refundRange}))
        formData.append('orderLines', JSON.stringify(orderLines))
        formData.append('orderSummary', JSON.stringify(summaryAll))

        dpsProvider
            .submitOrder(formData)
            .then((resp) => {
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                    confirmationText: 'Przejdź na stronę główną',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    redirect('/')
                })
            })
            .catch((resp) => {
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    const Summary = () => {

        return (
            <>
                <Box sx={checkoutStyles}>
                    <Typography>Wartość zamówienia</Typography>
                    <Typography>{currencyFormatter.format(summaryAll?.summaryTotal??0)}</Typography>
                </Box>
                {dpsPreferences.orderType == 1 && (
                    <>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty Pacjentów</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryPatient??0)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty NFZ</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryNfz??0)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty DPS</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryDps??0)}</Typography>
                        </Box>
                    </>
                )}
            </>
        )
    }

    return (
        <Box>
            <Card>
                <Title title="Zamówienie DPS" />
                <CardContent>
                    {!dpsPreferences ? (
                        <OrderTypeSelection />
                    ) : (
                        <Box>
                            <NewTemplateModal isOpen={modalOpen} setIsOpen={setModalOpen} products={{ ...dpsPreferences, patients: dpsDetails }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setDpsPreferences(false)
                                        setDpsDetails([])
                                        setSummaryAll({})
                                        setShouldSelectAll(true)
                                    }}
                                >
                                    Wróć do ustawień
                                </Button>
                                <Typography variant="subtitle1" maxWidth={400}>
                                    {dpsPreferences && getTitle(dpsPreferences)}
                                </Typography>
                                <TextField
                                    select
                                    variant="outlined"
                                    sx={{ minWidth: 200 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Okres:</InputAdornment>,
                                    }}
                                    value={refundRange}
                                    onChange={(e) => setRefundRange(e.target.value)}
                                >
                                    {refundRangeOptions.map((o) => (
                                        <MenuItem value={o.value}>{o.label}</MenuItem>
                                    ))}
                                </TextField>
                                <Box sx={{ display: 'flex' }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        label="Rozwiń/zwiń wszystkie"
                                        control={<Switch onClick={() => setExpandAll(!expandAll)} />}
                                    />
                                    <FormControlLabel
                                        labelPlacement="start"
                                        label="Zaznacz/odznacz wszystkie"
                                        control={<Checkbox  onClick={() => selectAll()} />}
                                    />
                                </Box>
                            </Box>
                            {patients && (
                                <Box sx={{ marginTop: 3 }}>
                                    {patients?.map((patient) => (
                                        <Box sx={{ display: 'flex' }}>
                                            <Accordion
                                                key={patient.id}
                                                expanded={expandedRef.current[patient.id] || expandAll}
                                                onChange={handleChange(patient.id)}
                                                sx={{ flex: 1 }}
                                            >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <PatientSummary
                                                        patient={patient}
                                                        active={expandedRef.current[patient.id] || expandAll}
                                                        dpsDetails={dpsDetails}
                                                        _refundStatuses={refundStatuses}
                                                        dpsPreferences={dpsPreferences}
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box>
                                                        <OrderLines patient={patient} forceUpdate={forceUpdate} />
                                                    </Box>
                                                </AccordionDetails>
                                                <AccordionActions>
                                                    <ProductSearch patient={patient} forceUpdate={forceUpdate} />
                                                </AccordionActions>
                                            </Accordion>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '4px' }}>
                                                <Checkbox
                                                    sx={{ alignSelf: 'center' }}
                                                    disabled={
                                                        !dpsDetails[getPatientIndex(patient._id)] ||
                                                        !dpsDetails[getPatientIndex(patient._id)]?.products?.length
                                                    }
                                                    checked={dpsDetails[getPatientIndex(patient._id)]?.selected}
                                                    onChange={() => handleSelect(patient._id)}
                                                />
                                                {dpsDetails[getPatientIndex(patient._id)]?.selected && (
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={dpsDetails[getPatientIndex(patient._id)]?.isCash}
                                                                onChange={(e) => handleCash(patient._id)}
                                                            />
                                                        }
                                                        label={
                                                            <Typography fontSize="0.75rem" variant="body2">
                                                                Czy płatność nastąpi gotówką
                                                            </Typography>
                                                        }
                                                        labelPlacement="top"
                                                        sx={{ margin: 0 }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', margin: '30px 0' }}>
                                <Box sx={{ flex: 1 }} />
                                <Box sx={{ flex: 1 }}>
                                    <Divider sx={{ margin: '10px 0' }} />
                                    <Summary />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                                        <Button
                                            disabled={!canSubmitOrder(false) || sendingRef.current}
                                            onClick={() => setModalOpen(true)}
                                            sx={{ marginRight: '20px' }}
                                            variant="contained"
                                            color="warning"
                                            startIcon={<DynamicFormIcon />}
                                        >
                                            Zapisz jako szablon
                                        </Button>
                                        <Button
                                            disabled={!canSubmitOrder(true) || sendingRef.current}
                                            onClick={() => submitOrder()}
                                            variant="contained"
                                            color="success"
                                        >
                                            Przejdź do realizacji zamówienia
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}
