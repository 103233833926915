import React, { useEffect, useState } from 'react'
import { useStore } from 'react-admin'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, plPL } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import fastOrderProvider from '../../../config/fastOrderProvider'
import { useSnackbar } from 'notistack'
import EditToOrder from './EditToOrder'
import ProductModal from '../../CustomElements/ProductModal'

const checkoutStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export default ({ patient, forceUpdate }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [dpsDetails, setDpsDetails] = useStore('order_dps_details', [])
    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', [])
    const [rows, setRows] = useState([])
    const [openProductModal, setOpenProductModal] = React.useState(false)
    const [dataProductModal, setDataProductModal] = React.useState({})
    

    useEffect(() => {
        setRows(dpsDetails.find((obj) => obj.patient_id == patient._id)?.products ?? [])
    }, [dpsDetails])

    const currencyFormatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    })

    const getRefundPrice = (row) => {
        return row?.dpsPrice ?? row?.[`refund_price_${patient?.refundDocument?.refundReduction}`]; 
    }

    const columns: GridColDef[] = [
        { field: 'img', headerName: 'Zdjęcie', flex: 1 },
        { field: 'reference', headerName: 'SKU', flex: 1 },
        {
            field: 'name',
            headerName: 'Nazwa',
            valueGetter: (params) => {
                return `${params.value || ''} ${params?.row?.attributes?.map((opt) => `${opt.attribute_name} - ${opt.name}`)?.join(', ') || ''}`
            },
            flex: 1.5,
        },
        {
            field: 'price',
            headerName: 'Brutto za jw.',
            flex: 0.5,
            valueGetter: (params) => {
                return params.row.price
            },
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        {
            field: 'priceTotal',
            headerName: 'Wartość',
            valueGetter: (params) => {
                return (
                    params.row.price *
                    params.row?.quantity
                )
            },
            flex: 0.5,
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        {
            field: 'pricePatient',
            headerName: 'Dopłata pacjenta',
            flex: 0.5,
            valueGetter: (params) => {
                return ((dpsPreferences?.orderType == 1 ? getRefundPrice(params.row) : 0) * params.row?.quantity) 
            },
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        {
            field: 'edit',
            type: 'actions',
            getActions: (params: GridRowParams) => [<EditToOrder row={params?.row} patient={patient} forceUpdate={forceUpdate} />],
            flex: 1,
        },
    ]

    const Footer = () => {
        const patientIndex = dpsDetails.findIndex((obj) => obj.patient_id == patient._id);
        const _summaryTotal = dpsDetails[patientIndex]?.summaryTotal ?? 0;

        const _summaryPatient = dpsDetails[patientIndex]?.summaryPatient?? 0;
        const _summaryDps = dpsDetails[patientIndex]?.summaryDps?? 0;
        const _summaryNfz = dpsDetails[patientIndex]?.summaryNfz?? 0;


        return (
            <Box sx={{ display: 'flex', margin: '10px' }}>
                <Box sx={{ flex: 3 }}></Box>
                <Box sx={{ flex: 1 }}>
                    <Box sx={checkoutStyles}>
                        <Typography>Wartość zamówienia</Typography>
                        <Typography>{currencyFormatter.format(_summaryTotal)}</Typography>
                    </Box>
                    {dpsPreferences.orderType == 1 && (
                        <>
                            <Divider sx={{ margin: '10px 0' }} />
                            <Box sx={checkoutStyles}>
                                <Typography>Wartość dopłaty Pacjentów</Typography>
                                <Typography>{currencyFormatter.format(_summaryPatient)}</Typography>
                            </Box>
                            <Divider sx={{ margin: '10px 0' }} />
                            <Box sx={checkoutStyles}>
                                <Typography>Wartość dopłaty NFZ</Typography>
                                <Typography>{currencyFormatter.format(_summaryNfz)}</Typography>
                            </Box>
                            <Divider sx={{ margin: '10px 0' }} />
                            <Box sx={checkoutStyles}>
                                <Typography>Wartość dopłaty DPS</Typography>
                                <Typography>{currencyFormatter.format(_summaryDps)}</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        )
    }

    const onRowClick = (params, event, details) => {
        setDataProductModal(params.row)
        setOpenProductModal(true)
    }

    return (
        <Box>
            <ProductModal open={openProductModal} setOpen={setOpenProductModal} data={dataProductModal} />
            <Box
                style={{
                    width: '100%',
                    minHeight: '350px',
                    display: 'grid',
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row?.id}
                    onRowClick={onRowClick}
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'rgba(0,0,0,.07)',
                        },
                        '& .odd': {
                            backgroundColor: 'rgba(0,0,0,.02)',
                        },
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                pricePatient: dpsPreferences?.orderType == 1,
                            },
                        },
                    }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
                    slots={{
                        toolbar: () => (
                            <Box>
                                <GridToolbarContainer>
                                    <Box display="flex" justifyContent="space-between" width="100%" marginX="10px">
                                        <Typography variant="subtitle2" marginY={1.5}>
                                            Linie zamówienia
                                        </Typography>
                                        <Box>
                                            <GridToolbarDensitySelector />
                                            <GridToolbarColumnsButton />
                                        </Box>
                                    </Box>
                                </GridToolbarContainer>
                                <Divider sx={{ opacity: 1 }} />
                            </Box>
                        ),
                        footer: Footer,
                    }}
                    localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </Box>
    )
}
