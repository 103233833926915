import { Button, Card } from '@mui/material'
import { Title } from 'react-admin'


export default () => {

    return <Card className='p-3'>
        <Title title="Reklamacje" />
        <div className='d-flex w-100 p-2' >
                <div className='col'>
                    <h4>REKLAMACJE ILOŚCIOWE</h4>
                    <ul>
                        <li>brak lub nadwyżka produktu w dostawie</li>
                        <li>uszkodzenie produktu w dostawie (produkt zgnieciony, rozerwany, mokry) </li>
                        <li>niezgodność datoserii produktu w dostawie</li>
                        <li>zwrot produktu </li>
                    </ul>
                    
                    <a
                        href={`https://reklamacje.tzmo.com.pl/reklamacje-ilosciowe/`}
                        target="_blank">
                        <Button color='warning' variant='contained'>
                            Przejdź do formularza
                        </Button>
                    </a>
                </div>
                <div className='col'>
                    <h4>REKLAMACJE JAKOŚCIOWE</h4>
                    <ul>
                        <li>wada produktu</li>
                        <li>wada opakowania jednostkowego (nieszczelne zamknięcie opakowania) </li>
                        <li>niezgodna ilość produktu w opakowaniu jednostkowym</li>
                        <li>nieprawidłowy wyrób w opakowaniu jednostkowym </li>
                    </ul>
                    <a
                        href={`https://reklamacje.tzmo.com.pl/klient-instytucjonalny/`}
                        target="_blank">
                        <Button color='warning' variant='contained'>
                            Przejdź do formularza
                        </Button>
                    </a>
                </div>
        </div>
        </Card>

}